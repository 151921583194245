<template>
  <v-container class="content-class grey lighten-5">
    <v-row justify="center">
      <v-col v-for="(item, n) in notifica_arr" :key="n" cols="6" md="4">
        <v-card class="mx-auto" max-width="344">
          <v-img
            height="250"
            src="/assets/images/happyb.png"
            v-if="item.categoria == 'cumple'"
          ></v-img>
          <v-img
            height="250"
            src="/assets/images/background/fondo1.jpg"
            v-if="item.categoria !== 'cumple'"
          ></v-img>
          <v-card-text>
            <p class="text-h4 text--primary">
              {{ item.titulo }}
            </p>

            <div class="text--primary">
              {{ item.mensaje }}
            </div>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Notificaciones from "@/services/notificacionService";
export default {
  name: "Notofocaciones",
  data: () => ({
    reveal: false,
    notifica_arr: [],
  }),
  mounted() {
    this.CargaNotifica();
  },
  methods: {
    async CargaNotifica() {
      const notifica_service = new Notificaciones("notificaciones", "A");
      const response = await notifica_service.getNotificaciones();
      //console.log('response', response)
      response.forEach((element) => {
        let obj_notifica = {};
        obj_notifica = element;
        this.notifica_arr.push(obj_notifica);
      });

      //console.log("this.notifica_arr :>> ", this.notifica_arr);
    },
  },
};
</script>
<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
