// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore } from "firebase/firestore";
//import {getAuth onAuthStateChanged, getRedirectResult} from "firebase/auth";
import { getAuth } from "firebase/auth";

//import { getStorage, ref, listAll } from "firebase/storage";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCOt2X5v6fKvf0M-5cb3ihnvVPgWyZOf0M",
  authDomain: "syngentastorage.firebaseapp.com",
  databaseURL: "https://syngentastorage-default-rtdb.firebaseio.com",
  projectId: "syngentastorage",
  storageBucket: "syngentastorage.appspot.com",
  messagingSenderId: "214235395765",
  appId: "1:214235395765:web:1855394bc5c9f9cb56b0fc",
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);
const fauth = getAuth(firebase);
const fstorage = getStorage(firebase);
// Create a storage reference from our storage service

//const listRef = ref(fstorage,"gs://syngentastorage.appspot.com/pais/colombia/productos/revus/");

/*
listAll(listRef)
  .then((res) => {
    res.prefixes.forEach((folderRef) => {
      // All the prefixes under listRef.
      console.log(`folderRef`, folderRef);
    });
    res.items.forEach((itemRef) => {
      // All the items under listRef.
      console.log(`itemRef`, itemRef);
    });
  })
  .catch((error) => {
    // Uh-oh, an error occurred!
    console.log(`error`, error);
  });
*/
///////
export { db, fauth, fstorage };
