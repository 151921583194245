import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import store from "./store";

// ------- alert---------//
import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

// ------- VueSession ---------//
// import VueSession from "vue-session";
// Vue.use(VueSession);

// ------- VueProgressBar ---------//
import VueProgressBar from "vue-progressbar";
Vue.use(VueProgressBar, {
  color: "#55D555",
  failedColor: "red",
  height: "7px",
  thickness: "7px",
  transition: {
    speed: "5s",
    termination: 300,
  },
});

Vue.config.productionTip = false;

import vuetify from "@/plugins/vuetify"; // path to vuetify export
Vue.use(vuetify);
Vue.use(store);

//#################################################################
Vue.prototype.$colorfondoapp = "light-green lighten-1";
//#################################################################

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
