import { db } from "@/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

export default class notificaService {
  constructor(tabla, estado) {
    this.tabla = tabla;
    this.estado = estado;
  }

  getNotificaciones() {
    const q = query(
      collection(db, this.tabla),
      where("estado", "==", this.estado)
    );

    return new Promise((resolve, reject) => {
      onSnapshot(
        q,
        (querySnapshot) => {
          const notificacion_list = [];
          querySnapshot.forEach((doc) => {
            notificacion_list.push(doc.data());
          });
          resolve(notificacion_list);
        },
        reject
      );
    });
  }
}
