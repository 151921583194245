<template>
  <div class="container">
    <HelloWorld msg="Bienvenido a Ornamentales !!"/>
    <v-spacer></v-spacer>
    <Notifica/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Notifica from '@/components/Notificaciones.vue'
export default {
  name: 'Home',
  components: {
    HelloWorld,
    Notifica
  }
}
</script>
