<template>
  <div>
    {{msg}}
  </div>
</template>

<script>

  export default {
    props: ['msg'],
    data() {
      return {

      }
    },
    mounted() {

    },
    methods: {

    },
  }
</script>

<style lang="scss" scoped>

</style>