import Vue from "vue";
import Vuetify from "vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/dist/vuetify.min.css";

//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      light: {
        primary: "#3f51b5",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
      },
    },
  },
};

export default new Vuetify(opts);
