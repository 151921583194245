import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/prod",
    name: "Productos",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/productos/productos.vue"),
  },
  {
    path: "/prod/:prod",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/productos/productos.vue"),
  },
  {
    path: "/cult",
    name: "Cultivos",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/cultivos/cultivos.vue"),
  },
  {
    path: "/plag",
    name: "Plagas",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/plagas/plagas.vue"),
  },
  {
    path: "/form1",
    name: "Fincas",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/fincas/registro.vue"),
  },
  {
    path: "/preg",
    name: "Preguntas",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/fincas/preguntas.vue"),
  },
  {
    path: "/admin",
    name: "Admin-User",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/usuarios/admin.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/usuarios/login.vue"),
    children: [
      {
        path: "/login/:model",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/usuarios/login.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
